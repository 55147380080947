.box{
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius : 5px;
  -moz-border-radius : 5px;
  -ms-border-radius : 5px;
  -o-border-radius : 5px;
}

.upload-area-title{
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.input-box{
    margin-top: 10;
}

.uploadlabel{
    width: 100%;
    min-height:  100px;
    background: #18a7ff0d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #18a7ff82;
    cursor: pointer;
}

.uploadlabel span{
    font-size: 70px;
    color: var(--blue);
}

.uploadlabel p{
    color: var(--blue);
    font-size: 20px;
    font-weight: 800;
    font-family: cursive;
}

.uploaded{
    margin: 10px 0;
    font-size: 16px;
    font-weight: 700;
    color: var(--gray);
}

.showfilebox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px 15px;
    box-shadow: #0000000d 0px 0px 0px 1px,
                #d1d5db3d 0px 0px 0px 1px inset;
    
}


.showfilebox .leftbx{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.filetype{
    color: #ff0000;
    padding: 5px 5px;
    text-transform: 700;
    border-radius: 3px;
    -webkit-border-radius : 3px;
    -moz-border-radius : 3px;
    -ms-border-radius : 3px;
    -o-border-radius : 3px;
}

.leftbx h3{
    font-weight: 600;
    font-size: 18px;
    color: #292F42;
    margin: 0;
}

.rightbx span{
    background: var(--blue);
    color: #fff;
    width: 25px;
    height: 25px;
    font-size: 25px;
    line-height: 25px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius : 50%;
    -moz-border-radius : 50%;
    -ms-border-radius : 50%;
    -o-border-radius : 50%;
}